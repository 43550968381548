const renderDataClickable = () => {
    if (document.querySelector("[data-clickable]")) {
        document.querySelectorAll("[data-clickable]").forEach((wrapper) => {
            const link = wrapper.querySelector("[data-wrapper-link]");
            let tagName = 'a';

            if (link) {
                tagName = link.tagName.toLowerCase();
            }

            if (tagName === 'button') {
                wrapper.addEventListener("click", (event) => {
                    link.click();
                    event.preventDefault();
                });
            } else {
                wrapper.addEventListener("click", (event) => {
                    window.location = link.href;
                    event.preventDefault();
                });
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', renderDataClickable);
document.addEventListener('refreshDataClickable', renderDataClickable);
