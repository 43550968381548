export default function splideGeneralOptions(perPage = 4) {
    return {
        pagination: true,
        arrows: true,
        cover: true,
        gap: '5rem',
        padding: '0.4rem',
        perPage: perPage <= 4 ? perPage : 4,
        breakpoints: {
            1980: {
                gap: '1rem'
            },
            1200:{
                perPage: 3,
            },
            1000: {
                perPage: 2,
                gap: '.7rem',
            },
            700: {
                perPage: 1,
                gap: '.7rem',
                // padding: '5rem',
            },
        },
    };
}
